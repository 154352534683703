import { render, staticRenderFns } from "./NavHelpCentre.vue?vue&type=template&id=1922f110"
import script from "./NavHelpCentre.vue?vue&type=script&lang=ts"
export * from "./NavHelpCentre.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavButton: require('/app/components/rebrand-header/NavButton.vue').default,NavHelpCentreItem: require('/app/components/rebrand-header/NavHelpCentreItem.vue').default,NavMenu: require('/app/components/rebrand-header/NavMenu.vue').default})
